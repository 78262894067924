import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import UserAudioUpload from "components/CustomUpload/UserAudioUpload.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import EditIcon from "@material-ui/icons/Edit";
import PublicIcon from "@material-ui/icons/Public";
import MicIcon from "@material-ui/icons/Mic";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import ClearIcon from "@material-ui/icons/Clear";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import Muted from "components/Typography/Muted.js";

import profileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useProfileStyles = makeStyles(profileStyles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const matchTypes = {
  fuzzy: "FUZZY",
  regex: "REGEX",
};

const languages = {
  ar: "ARABIC",
  en: "ENGLISH",
};

const mapperTypes = {
  ar: "ar2bw",
};

const defaultResult = {
  text: "-",
  cleaned: "-",
  result: {
    matched: "-",
    expression: "-",
    score: "-",
    type: "-",
  },
};

const defaultThreshold = 80;

const defaultMatchCandidates = [
  {
    _id: `${uuidv4()}`,
    type: matchTypes.regex,
    text: "Bismillah Allahu Akbar",
  },
  {
    _id: `${uuidv4()}`,
    type: matchTypes.regex,
    text: "bsm Allh Allh Alkbr",
  },
  {
    _id: `${uuidv4()}`,
    type: matchTypes.fuzzy,
    text: "Bismillah Allahu Akbar",
    threshold: defaultThreshold,
  },
  {
    _id: `${uuidv4()}`,
    type: matchTypes.fuzzy,
    text: "bsm Allh Allh Alkbr",
    threshold: defaultThreshold,
  },
];

const AiAudioProcessing = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const profileClasses = useProfileStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [formattedUserName, setFormattedUserName] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");
  const [alert, setAlert] = React.useState(null);

  const [language, setLanguage] = React.useState(languages.ar);
  const [useMapper, setUseMapper] = React.useState([1]);

  const [matchCandidates, setMatchCandidates] = React.useState(
    JSON.parse(JSON.stringify(defaultMatchCandidates))
  );

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalId, setModalId] = React.useState(`${uuidv4()}`);
  const [modalMatchType, setModalMatchType] = React.useState(matchTypes.fuzzy);
  const [modalText, setModalText] = React.useState("");
  const [modalThreshold, setModalThreshold] = React.useState("80");
  const [suffix, setSuffix] = React.useState(".mp3");
  const [base64Audio, setBase64Audio] = React.useState("");
  const [results, setResults] = React.useState(
    JSON.parse(JSON.stringify(defaultResult))
  );
  const [processing, setProcessing] = React.useState(false);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId =
        typeof userProfileData.superuser !== "undefined"
          ? userProfileData._id
          : returnData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const getLookups = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          id: `${queryParams.userId}_ai_audio_candidates`,
        };
        try {
          const responseData = await ApiService.getSingleLookup(params);
          const state = JSON.parse(responseData.value);
          setLanguage(state.language);
          setUseMapper(state.mapper ? [1] : []);
          setMatchCandidates(state.candidates);
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await getLookups(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.location.search]);

  const handleUpdateState = async (language_, mapper, candidates) => {
    const value = JSON.stringify({
      language: language_,
      mapper: mapper.length > 0,
      candidates: candidates.map((entry) => {
        const newEntry = { ...entry };
        delete newEntry["actions"];
        return newEntry;
      }),
    });
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      id: `${queryParams.userId}_ai_audio_candidates`,
      type: ApiService.lookupTypes.state,
      access: ApiService.lookupPermissionTypes.private,
      value,
      flags: [],
    };
    try {
      await ApiService.upsertLookup(params);
    } catch (e) {
      console.error(e);
    }
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalId(`${uuidv4()}`);
    setModalMatchType(matchTypes.fuzzy);
    setModalText("");
    setModalThreshold(`${defaultThreshold}`);
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("edit-match-candidate")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                setModalId(elem._id);
                setModalMatchType(elem.type);
                setModalText(elem.text);
                if (typeof elem.threshold === "undefined") {
                  setModalThreshold(`${defaultThreshold}`);
                } else {
                  setModalThreshold(`${elem.threshold}`);
                }
                setConfirmModalButtonText(t("update"));
                setShowModal(true);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-match-candidate")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="remove"
              onClick={() => {
                handleDeleteMatchCandidate(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleDeleteMatchCandidate = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-match-candidate")}?`}
        onConfirm={() => {
          deleteMatchCandidate(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem.itemId}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteMatchCandidate = async (matchCandidate) => {
    setAlert(null);
    try {
      const matchCandidates_ = matchCandidates.filter(
        (candidate) => candidate._id !== matchCandidate._id
      );
      await handleUpdateState(language, useMapper, matchCandidates_);
      setMatchCandidates(matchCandidates_);
      operationResult(true, t("match-candidate-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const showErrorMessage = (messageContent) => {
    setErrorMessage(messageContent);
    setTimeout(() => {
      setErrorMessage("");
    }, ApiService.messageTimeout);
  };

  const handleAddUpdateMatchCandidate = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      return clearModalState();
    }

    const text = modalText.trim();
    const validText = text.length > 0;
    let threshold = undefined;
    let validThreshold = true;
    if (modalMatchType === matchTypes.fuzzy) {
      try {
        threshold = parseInt(modalThreshold);
        validThreshold = threshold >= 0 && threshold <= 100;
      } catch (e) {
        validThreshold = false;
      }
    }
    const checksPassed = validText && validThreshold;
    if (!checksPassed) {
      const messageContent = [];
      if (!validText) {
        messageContent.push(t("invalid-text"));
      }
      if (!validThreshold) {
        messageContent.push(t("invalid-threshold"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const matchCandidate = {
      _id: modalId,
      text,
      type: modalMatchType,
    };
    if (typeof threshold !== "undefined") {
      matchCandidate.threshold = threshold;
    }

    const existingCandidate = matchCandidates.find(
      (candidate) => candidate._id === modalId
    );
    let matchCandidates_;
    if (existingCandidate) {
      matchCandidates_ = matchCandidates.map((candidate) =>
        candidate._id === matchCandidate._id ? matchCandidate : candidate
      );
    } else {
      matchCandidates_ = [...matchCandidates, matchCandidate];
    }

    await handleUpdateState(language, useMapper, matchCandidates_);
    setMatchCandidates(matchCandidates_);

    return showSuccessModalMessage(t("match-candidate-successfully-processed"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleSetBase64Audio = (base64AudioString, extension) => {
    setBase64Audio(base64AudioString);
    setSuffix(`.${extension}`);
  };

  const handleLLMProcessAudio = async () => {
    if (base64Audio.length === 0) {
      return;
    }

    // Set default results
    setProcessing(true);
    setResults(JSON.parse(JSON.stringify(defaultResult)));

    const params = {
      env: ApiService.environment,
      audioBase64: base64Audio,
    };
    const config = {
      suffix,
    };
    const selectedLanguage = Object.keys(languages).find(
      (key) => languages[key] === language
    );
    config.language = selectedLanguage;
    if (useMapper.length > 0) {
      const mapper = mapperTypes[selectedLanguage];
      if (mapper) {
        config.mapper = mapper;
      }
    }
    const regexMatch = [];
    const fuzzyMatch = [];
    // eslint-disable-next-line no-unused-vars
    for (const entry of matchCandidates) {
      if (entry.type === matchTypes.regex) {
        regexMatch.push(entry.text);
      } else {
        fuzzyMatch.push([entry.text, entry.threshold]);
      }
    }
    config.regexMatch = regexMatch;
    config.fuzzyMatch = fuzzyMatch;
    params.config = config;

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const response = await ApiService.llmProcessAudio(params, signal);
      const result_ = JSON.parse(JSON.stringify(defaultResult));
      result_.text = response.text;
      result_.cleaned = response.cleaned;
      const keys = Object.keys(response.result);
      // eslint-disable-next-line no-unused-vars
      for (const key of keys) {
        result_.result[key] = response.result[key];
      }
      if (keys.includes("matched")) {
        result_.result["matched"] = result_.result["matched"]
          ? t("true")
          : t("false");
      }
      if (keys.includes("type")) {
        result_.result["type"] = result_.result["type"].toUpperCase();
      }
      setResults(result_);
    } catch (e) {
      showErrorMessage(e.message);
    }
    setProcessing(false);
  };

  const handleProcessResults = (data) => {
    return [
      {
        attribute: `${t("raw-text")}`,
        value: data.text,
      },
      {
        attribute: `${t("cleaned-text")}`,
        value: results.cleaned,
      },
      {
        attribute: `${t("matched")}`,
        value: results.result.matched,
      },
      {
        attribute: `${t("type")}`,
        value: results.result.matched ? results.result.type : "-",
      },
      {
        attribute: `${t("expression")}`,
        value: results.result.matched ? results.result.expression : "-",
      },
      {
        attribute: `${t("score")}`,
        value:
          results.result.matched && !!results.result.score
            ? results.result.score
            : "-",
      },
    ];
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{t("match-candidate")}</h4>
        </DialogTitle>
        <form onSubmit={handleAddUpdateMatchCandidate}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("type")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="match-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalMatchType}
                    onChange={(e) => {
                      setModalMatchType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "matchType",
                      id: "match-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("type")}
                    </MenuItem>
                    {Object.values(matchTypes).map((matchType) => {
                      return (
                        <MenuItem
                          key={matchType}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={matchType}
                        >
                          {matchType}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("text")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="text"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalText,
                    disabled: showModalProgress,
                    onChange: (e) => {
                      setModalText(e.target.value);
                    },
                  }}
                  helpText={t("text")}
                />
              </GridItem>
            </GridContainer>
            {modalMatchType === matchTypes.fuzzy && (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    style={{ float: "left" }}
                  >
                    {t("threshold")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="threshold"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "threshold",
                      value: modalThreshold,
                      disabled: showModalProgress,
                      onChange: (e) => {
                        setModalThreshold(e.target.value);
                      },
                    }}
                    helpText={t("threshold")}
                  />
                </GridItem>
              </GridContainer>
            )}
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <PublicIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("select-language")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={5}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left", paddingTop: "40px" }}
                  >
                    {t("language")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={7}>
                  <FormControl
                    fullWidth
                    className={formClasses.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="language-type"
                      className={formClasses.selectLabel}
                    ></InputLabel>
                    <Select
                      value={language}
                      onChange={async (e) => {
                        const language_ = e.target.value;
                        await handleUpdateState(
                          language_,
                          useMapper,
                          matchCandidates
                        );
                        setLanguage(language_);
                      }}
                      MenuProps={{ className: formClasses.selectMenu }}
                      classes={{ select: formClasses.select }}
                      inputProps={{
                        name: "languageType",
                        id: "language-type",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: formClasses.selectMenuItem,
                        }}
                      >
                        {t("language")}
                      </MenuItem>
                      {Object.values(languages).map((languageType) => {
                        return (
                          <MenuItem
                            key={languageType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={languageType}
                          >
                            {languageType}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer className={`${profileClasses.description}`}>
                <GridItem xs={12} sm={5}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("mapper")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={7} style={{ float: "left" }}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControlLabel
                      style={{ float: "left" }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={async () => {
                            const currentIndex = useMapper.indexOf(1);
                            const newChecked = [...useMapper];
                            if (currentIndex === -1) {
                              newChecked.push(1);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }
                            await handleUpdateState(
                              language,
                              newChecked,
                              matchCandidates
                            );
                            setUseMapper(newChecked);
                          }}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot,
                          }}
                          checked={useMapper.length > 0}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot,
                      }}
                      label={t("use-mapper")}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <MicIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("record-audio")}</h4>
            </CardHeader>
            <CardBody>
              <UserAudioUpload setBase64Audio={handleSetBase64Audio} />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <EqualizerIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("automatic-speech-recognition")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <ReactTable
                    data={handleProcessResults(results)}
                    columns={[
                      {
                        Header: "",
                        accessor: "attribute",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "",
                        accessor: "value",
                        sortable: false,
                        filterable: false,
                        headerStyle: { textAlign: "right" },
                        Cell: (row) => (
                          <div style={{ textAlign: "right" }}>{row.value}</div>
                        ),
                      },
                    ]}
                    defaultPageSize={6}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12}>
                  <Button
                    color="primary"
                    style={{ float: "right" }}
                    onClick={() => handleLLMProcessAudio()}
                    disabled={processing}
                  >
                    {processing ? t("processing") : t("process")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <DataUsageIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("match-candidates")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(matchCandidates)}
                filterable
                columns={[
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("text"),
                    accessor: "text",
                  },
                  {
                    Header: t("threshold"),
                    accessor: "threshold",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={5}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="rose"
        icon={AddAlert}
        message={errorMessage}
        open={errorMessage.length > 0}
        closeNotification={() => setErrorMessage("")}
        close
      />
      {alert}
    </div>
  );
};

AiAudioProcessing.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AiAudioProcessing;
