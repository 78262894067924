import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import PublicIcon from "@material-ui/icons/Public";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import TextField from "@material-ui/core/TextField";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const AiChat = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const alertClasses = useAlertStyles();

  const [errorMessage, setErrorMessage] = React.useState("");
  const [alert, setAlert] = React.useState(null);

  const [context, setContext] = React.useState("");
  const [prompt, setPrompt] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");

  const [chatHistory, setChatHistory] = React.useState("");
  const [chatSummary, setChatSummary] = React.useState("");

  const [processing, setProcessing] = React.useState(false);

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const showErrorMessage = (messageContent) => {
    setErrorMessage(messageContent);
    setTimeout(() => {
      setErrorMessage("");
    }, ApiService.messageTimeout);
  };

  const handleLLMProcessChat = async () => {
    // Set default results
    setProcessing(true);

    const params = {
      env: ApiService.environment,
      context,
      question: prompt,
      chatHistory,
    };
    if (chatSummary.length > 0) {
      params.chatHistory = chatSummary;
    } else if (chatHistory.length === 0) {
      params.chatHistory = chatHistory;
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const responseData = await ApiService.llmProcessQuestion(params, signal);
      const newEntry = `Human: ${responseData.question}\nAI: ${responseData.answer}`;
      if (responseData.chatHistory.length > 0) {
        setChatHistory(`${responseData.chatHistory}\n${newEntry}`);
      } else {
        setChatHistory(newEntry);
      }
      setChatSummary("");
      setQuestion(responseData.question);
      setAnswer(responseData.answer);
      setPrompt("");
    } catch (e) {
      showErrorMessage(e.message);
    }
    setProcessing(false);
  };

  const handleClearChatHistory = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("clear-chat")}?`}
        onConfirm={() => {
          setAlert(null);
          setChatHistory("");
          setChatSummary("");
          setQuestion("");
          setAnswer("");
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const handleSummarizeChat = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("summarize-chat")}?`}
        onConfirm={() => {
          handleProcessChatSummary();
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const handleProcessChatSummary = async () => {
    setAlert(null);
    setProcessing(true);
    try {
      if (chatHistory.length > 0) {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const resposeData = await ApiService.llmProcessChatSummary(
          {
            env: ApiService.environment,
            chatHistory,
          },
          signal
        );
        setChatHistory(resposeData.chatSummary);
        setChatSummary(resposeData.chatSummary);
        setQuestion("");
        setAnswer("");
      }
      operationResult(true, t("chat-successfully-summarized"));
    } catch (e) {
      operationResult(false, e.message);
    }
    setProcessing(false);
  };

  const handleReturnData = (chat) => {
    if (chat.length === 0) {
      return [];
    }
    const lines = chat.split("\n");
    const conversation = lines.map((message) => {
      return { message };
    });
    return conversation.reverse();
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <PublicIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("context")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <TextField
                    required
                    id="text-context"
                    variant="outlined"
                    fullWidth
                    value={context}
                    multiline
                    rows={7}
                    disabled={processing}
                    onChange={(e) => setContext(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <EqualizerIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("prompt")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <TextField
                    required
                    id="text-prompt"
                    variant="outlined"
                    fullWidth
                    value={prompt}
                    multiline
                    rows={3}
                    disabled={processing}
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Button
                    color="primary"
                    style={{ float: "right", marginTop: "20px" }}
                    onClick={() => handleLLMProcessChat()}
                    disabled={processing}
                  >
                    {processing ? t("processing") : t("process")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              <ReactTable
                data={[{ answer }]}
                columns={[
                  {
                    Header: t("answer"),
                    accessor: "answer",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={1}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
                getTableProps={() => ({
                  style: { fontSize: "1rem" },
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              <ReactTable
                data={[{ question }]}
                columns={[
                  {
                    Header: t("question"),
                    accessor: "question",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={1}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
                getTableProps={() => ({
                  style: { fontSize: "1rem" },
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <DataUsageIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("chat-history")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleClearChatHistory();
                  }}
                  style={{ marginTop: "28px" }}
                  disabled={processing}
                >
                  {t("clear-chat")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  disabled={processing}
                  onClick={() => handleSummarizeChat()}
                >
                  {t("summarize-chat")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(chatHistory)}
                columns={[
                  {
                    Header: t("message"),
                    accessor: "message",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="rose"
        icon={AddAlert}
        message={errorMessage}
        open={errorMessage.length > 0}
        closeNotification={() => setErrorMessage("")}
        close
      />
      {alert}
    </div>
  );
};

AiChat.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AiChat;
