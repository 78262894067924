import React from "react";
import PropTypes from "prop-types";
import ApiService from "api/ApiService.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";

import Button from "components/CustomButtons/Button.js";

import { useTranslation } from "react-i18next";

const supportedPdfMimeTypes = {
  pdf: "application/pdf",
};

const UserBase64DocumentUpload = (props) => {
  const { t } = useTranslation();

  // File input fields
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [fileName, setFilename] = React.useState("");
  const fileInput = React.createRef();

  const showErrorMessage = (messageContent) => {
    setSuccessMessage("");
    setErrorMessage(messageContent);
    setTimeout(() => {
      setErrorMessage("");
    }, ApiService.messageTimeout);
  };

  const showSuccessMessage = (messageContent) => {
    setErrorMessage("");
    setSuccessMessage(messageContent);
    setTimeout(() => {
      setSuccessMessage("");
    }, ApiService.messageTimeout);
  };

  const handleDocumentChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    try {
      if (file) {
        const fileName_ = file.name;
        const fileExtension = fileName_.split(".").pop().toLowerCase();
        const mimeType = supportedPdfMimeTypes[fileExtension];
        if (mimeType) {
          const reader = new FileReader();
          reader.onload = function () {
            props.setBase64Document(reader.result, fileExtension);
          };
          reader.readAsDataURL(file);
          setFilename(fileName_);
          showSuccessMessage(`${t("file-selected")}: ${fileName_}`);
        }
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const resetFileInput = () => {
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  return (
    <div className="fileinput text-center">
      <input
        type="file"
        onChange={(e) => {
          handleDocumentChange(e);
          resetFileInput();
        }}
        accept=".pdf"
        ref={fileInput}
      />
      <div>
        {fileName.length === 0 ? (
          <Button simple color="primary" onClick={() => handleClick()}>
            {t("select-document")}
          </Button>
        ) : (
          <Button simple color="rose" onClick={() => handleClick()}>
            {t("change")}
          </Button>
        )}
      </div>
      <Snackbars
        place="bc"
        color="rose"
        icon={AddAlert}
        message={errorMessage}
        open={errorMessage.length > 0}
        closeNotification={() => setErrorMessage("")}
        close
      />
      <Snackbars
        place="bc"
        color="rose"
        icon={AddAlert}
        message={successMessage}
        open={successMessage.length > 0}
        closeNotification={() => setSuccessMessage("")}
        close
      />
    </div>
  );
};

UserBase64DocumentUpload.propTypes = {
  setBase64Document: PropTypes.func.isRequired,
};

export default UserBase64DocumentUpload;
