import React from "react";
import PropTypes from "prop-types";
import ApiService from "api/ApiService.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";

// core components
import Button from "components/CustomButtons/Button.js";

import { useTranslation } from "react-i18next";

const supportedAudioMimeTypes = {
  mp3: "audio/mpeg",
  mp4: "audio/mp4",
  mpeg: "audio/mpeg",
  mpga: "audio/mpeg",
  m4a: "audio/mp4",
  wav: "audio/wav",
  webm: "audio/webm",
};

const UserAudioUpload = (props) => {
  const { t } = useTranslation();

  // File input fields
  const [activeInput, setActiveInput] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const fileInput = React.createRef();

  // Recorder fields
  const [audioURL, setAudioURL] = React.useState("");
  const [isRecording, setIsRecording] = React.useState(false);
  const [recorder, setRecorder] = React.useState(null);

  React.useEffect(() => {
    const handleData = (e) => {
      setAudioURL(URL.createObjectURL(e.data));
      const reader = new FileReader();
      reader.onloadend = function () {
        props.setBase64Audio(reader.result, "webm");
      };
      reader.readAsDataURL(e.data);
    };

    if (recorder === null) {
      if (isRecording) {
        requestRecorder()
          .then((instance) => {
            instance.addEventListener("dataavailable", handleData);
            setRecorder(instance);
          })
          .catch(console.error);
      }
      return;
    }

    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }
    return () => {
      if (recorder) {
        recorder.removeEventListener("dataavailable", handleData);
      }
    };
  }, [recorder, isRecording, props]);

  const startRecording = () => {
    setActiveInput(false);
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const requestRecorder = async () => {
    const options = { mimeType: "audio/webm" };
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return new MediaRecorder(stream, options);
  };

  const showErrorMessage = (messageContent) => {
    setSuccessMessage("");
    setErrorMessage(messageContent);
    setTimeout(() => {
      setErrorMessage("");
    }, ApiService.messageTimeout);
  };

  const showSuccessMessage = (messageContent) => {
    setErrorMessage("");
    setSuccessMessage(messageContent);
    setTimeout(() => {
      setSuccessMessage("");
    }, ApiService.messageTimeout);
  };

  const loadAudioFromBase64 = (base64String, mimeType) => {
    const byteCharacters = atob(base64String.split(",")[1]);
    const byteNumbers = Array.from(byteCharacters, (char) =>
      char.charCodeAt(0)
    );
    const byteArray = new Uint8Array(byteNumbers);
    const audioBlob = new Blob([byteArray], { type: mimeType });

    setAudioURL(URL.createObjectURL(audioBlob));
  };

  const handleAudioChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    try {
      if (file) {
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();
        const mimeType = supportedAudioMimeTypes[fileExtension];
        if (mimeType) {
          const reader = new FileReader();
          reader.onload = function () {
            loadAudioFromBase64(reader.result, mimeType);
            props.setBase64Audio(reader.result, fileExtension);
          };
          reader.readAsDataURL(file);
          setActiveInput(true);
          showSuccessMessage(`${t("file-selected")}: ${fileName}`);
        }
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const resetFileInput = () => {
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  return (
    <div className="fileinput text-center">
      <input
        type="file"
        onChange={(e) => {
          handleAudioChange(e);
          resetFileInput();
        }}
        accept=".mp3,.mp4,.mpeg,.mpga,.m4a,.wav,.webm"
        ref={fileInput}
      />
      <audio src={audioURL} controls />
      <div>
        <Button
          simple
          color="primary"
          disabled={isRecording}
          onClick={() => startRecording()}
        >
          {t("start-recording")}
        </Button>
        <Button
          simple
          color="primary"
          disabled={!isRecording}
          onClick={() => stopRecording()}
        >
          {t("stop-recording")}
        </Button>
        <Button simple color="primary" onClick={() => handleClick()}>
          {!activeInput ? t("select-file") : t("change")}
        </Button>
      </div>
      <Snackbars
        place="bc"
        color="rose"
        icon={AddAlert}
        message={errorMessage}
        open={errorMessage.length > 0}
        closeNotification={() => setErrorMessage("")}
        close
      />
      <Snackbars
        place="bc"
        color="rose"
        icon={AddAlert}
        message={successMessage}
        open={successMessage.length > 0}
        closeNotification={() => setSuccessMessage("")}
        close
      />
    </div>
  );
};

UserAudioUpload.propTypes = {
  setBase64Audio: PropTypes.func.isRequired,
};

export default UserAudioUpload;
